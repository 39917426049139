<template>
  <a-drawer
    :width="type == 4 ? '1200px' : '500px'"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="结算日" prop="settleDay" v-if="type == 1">
        <a-input v-model="form.settleDay" type="number" placeholder="请输入" style="width: 300px"/>
      </a-form-model-item>
      <a-form-model-item label="用量警告" prop="dosageWarn" v-if="type == 2">
        <a-input v-model="form.dosageWarn" type="number" placeholder="请输入" style="width: 300px"/>
      </a-form-model-item>
      <a-form-model-item label="上报周期" prop="reportingCycle" v-if="type == 3">
        <a-input v-model="form.reportingCycle" type="number" placeholder="请输入" style="width: 300px"/>
      </a-form-model-item>
      <a-form-model-item label="设备配价" v-if="type == 4">
        <a-select v-model="form.devOrdinateId" placeholder="请选择" style="width: 300px" @change="changeOrdinate">
          <a-select-option v-for="(item,key) in devOrdinateList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属公司" v-if="type == 7">
        <a-select v-model="form.tenantId" placeholder="请选择" style="width: 300px">
          <a-select-option v-for="(item,key) in companyList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="表底数" prop="residueWater" v-if="type == 5">
        <a-input v-model="form.residueWater" type="number" placeholder="请输入" style="width: 300px"/>
      </a-form-model-item>
      <a-form-model-item label="表号" prop="devSn" v-if="type == 6">
        <a-input v-model="form.devSn" type="number" placeholder="请输入" style="width: 300px"/>
      </a-form-model-item>
      <div v-if="type == 4 && form.devOrdinateId">
        <a-descriptions title="详情">
          <a-descriptions-item label="配价方案名称">{{ devOrdinateInfo.name }}</a-descriptions-item>
          <a-descriptions-item label="欠费是否关阀">
            <a-tag color="blue" v-if="devOrdinateInfo.sfQfValve == 0">否</a-tag>
            <a-tag color="orange" v-else-if="devOrdinateInfo.sfQfValve == 1">是</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="支付方式">
            <a-tag color="blue" v-if="devOrdinateInfo.payType == 0">平台预付费</a-tag>
            <a-tag color="blue" v-else-if="devOrdinateInfo.payType == 1">表端预付费</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="备注">{{ devOrdinateInfo.remark }}</a-descriptions-item>
        </a-descriptions>
        <!-- 数据展示 -->
        <a-descriptions title="阶梯">
          <a-descriptions-item>
            <a-table
              :loading="loading"
              :size="tableSize"
              rowKey="id"
              :columns="columns"
              :data-source="list"
              :pagination="false"
              :bordered="tableBordered">
              <span slot="configType" slot-scope="text, record">
                <dict-tag :options="dict.type['sys_yes_no']" :value="record.configType" />
              </span>
              <span slot="typeValue" slot-scope="text, record">
                <span v-if="record.typeValue == 1">是</span>
                <span v-if="record.typeValue == 0">否</span>
              </span>
              <span slot="endVale" slot-scope="text, record">
                <span v-if="record.endVale == 0">--</span>
                <span v-else>{{ record.endVale }}</span>
              </span>
              <span slot="createTime" slot-scope="text, record">
                {{ parseTime(record.createTime) }}
              </span>
            </a-table>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { deviceOrdinateList, deviceEditWarn, deviceOrdinateSz, deviceEditJsday, deviceEditZq, deviceOrdinateTypePage, deviceEditBds, deviceEditGhbh, deviceEditHbhao, deviceEditSsgs } from '@/api/pages/device'
import { companyPage } from '@/api/pages/company'
import { tableMixin } from '@/store/table-mixin'
export default {
  mixins: [tableMixin],
  name: 'CreateForm2',
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      devOrdinateList: [],
      devOrdinateInfo: null,
      companyList: [],
      type: null,
      formList: [],
      // 表单
      form: {
        settleDay: '', // 批量设置结算日
        dosageWarn: '', // 批量设置用量警告
        reportingCycle: '', // 批量设置上报周期
        residueWater: '', // 设置表底数
        devSn: '', // 更换表号
        devOrdinateId: undefined, // 批量设置配价方案
        tenantId: undefined // 批量设置所属公司
      },
      open: false,
      rules: {
        settleDay: [{ required: true, message: '结算日不能为空', trigger: 'blur' }],
        dosageWarn: [{ required: true, message: '用量警告不能为空', trigger: 'blur' }],
        reportingCycle: [{ required: true, message: '上报周期不能为空', trigger: 'blur' }],
        residueWater: [{ required: true, message: '表底数不能为空', trigger: 'blur' },
          { validator: this.validateNum, trigger: 'blur' }],
        devSn: [{ required: true, message: '表号不能为空', trigger: 'blur' },
          { min: 14, max: 14, message: '长度为14位', trigger: 'blur' }],
        devOrdinateId: [{ required: true, message: '请选择配价方案', trigger: ['blur', 'change'] }],
        tenantId: [{ required: true, message: '请选择所属公司', trigger: ['blur', 'change'] }]
      },

      loading: false,
      list: [],
      queryParam: {
        pageNum: 1,
        pageSize: 999999
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所属阶梯',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否无穷大',
          dataIndex: 'typeValue',
          scopedSlots: { customRender: 'typeValue' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用水量起始值',
          dataIndex: 'startVale',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用水量结束值',
          dataIndex: 'endVale',
          scopedSlots: { customRender: 'endVale' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '金额',
          dataIndex: 'waterMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 150,
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    deviceOrdinateList().then(response => {
      this.devOrdinateList = response.data
    })
    companyPage({ pageNum: 1, pageSize: 999999 }).then(response => {
      this.companyList = response.data.records
    })
  },
  computed: {
  },
  watch: {
  },
  methods: {
    validateNum (rule, value, callback) {
      // console.log(rule, value)
      if (!/^[0-9]*([\.][0-9]{1,2})?$/.test(value)) {
        callback(new Error('请输入的正确数字（最多两位小数）'))
      } else {
        callback()
      }
    },
    changeOrdinate(e) {
      console.log(e)
      this.devOrdinateList.map(item => {
        if (e === item.id) {
          this.devOrdinateInfo = item
        }
      })
      this.getList(e)
    },
    /** 查询参数列表 */
    getList (id) {
      this.oid = id || this.oid
      this.loading = true
      deviceOrdinateTypePage({ deviceOrdinateId: this.oid, ...this.queryParam }).then(response => {
          this.open = true
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        settleDay: '', // 批量设置结算日
        dosageWarn: '', // 批量设置用量警告
        reportingCycle: '', // 批量设置上报周期
        residueWater: '', // 设置表底数
        devSn: '', // 一键换表
        devOrdinateId: undefined, // 批量设置配价方案
        tenantId: undefined // 批量设置所属公司
      }
    },
    handleUpdate (type, ids) {
      console.log(type, ids)
      const that = this
      that.formList = []
      ids.map(item => {
        that.formList.push({
          id: item
        })
      })
      this.type = type
      if (type === 1) {
        this.formTitle = '批量设置结算日'
      } else if (type === 2) {
        this.formTitle = '批量设置用量警告'
      } else if (type === 3) {
        this.formTitle = '批量设置上报周期'
      } else if (type === 4) {
        this.formTitle = '批量设置配价方案'
      } else if (type === 5) {
        this.formTitle = '设置表底数'
      } else if (type === 6) {
        this.formTitle = '一键换表'
      } else if (type === 7) {
        this.formTitle = '批量设置所属公司'
      }
      this.open = true
      this.reset()
    },
    /** 提交按钮 */
    submitForm: function () {
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.type === 1) { // 批量设置结算日
            that.formList.map(item => {
              item.settleDay = that.form.settleDay
            })
            deviceEditJsday(this.formList).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else if (this.type === 2) { // 批量设置用量警告
            that.formList.map(item => {
              item.dosageWarn = that.form.dosageWarn
            })
            deviceEditWarn(this.formList).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else if (this.type === 3) { // 批量设置上报周期
            that.formList.map(item => {
              item.reportingCycle = that.form.reportingCycle
            })
            deviceEditZq(this.formList).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else if (this.type === 4) { // 批量设置配价方案
            that.formList.map(item => {
              item.devOrdinateId = that.form.devOrdinateId
            })
            deviceOrdinateSz(this.formList).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else if (this.type === 5) { // 设置表底数
            that.formList.map(item => {
              item.residueWater = that.form.residueWater
            })
            deviceEditBds(this.formList[0]).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else if (this.type === 6) { // 更换表号
            that.formList.map(item => {
              item.devSn = that.form.devSn
            })
            deviceEditHbhao(this.formList[0]).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else if (this.type === 7) { // 批量设置所属公司
            that.formList.map(item => {
              item.tenantId = that.form.tenantId
            })
            deviceEditSsgs(this.formList).then(response => {
              this.$message.success(
                '操作成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
@/api/pages/device
